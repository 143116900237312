<template>
	<v-form
		:readonly="onView && !update"
		ref="form"
		lazy-validation
		@submit.prevent="validateUser"
	>
		<v-card
			class="tw-overflow-x-hidden tw-overflow-y-auto tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6 "
		>
			<!-- {{ branch }} -->
			<v-card-text>
				<div class="tw-flex tw-justify-between tw-items-start">
					<h2
						class="tw-text-22 tw-font-semibold tw-text-black tw-mb-6 lg:tw-mb-12"
					>
						{{ $t('branch.branchInformation') }}
					</h2>
					<div v-if="onView && !update" class="tw-flex tw-space-s-3">
						<v-btn
							width="50"
							height="50"
							class="tw-rounded-md"
							depressed
							tile
							color="#F2CA51"
							outlined
							@click="onUpdateFromView"
							icon
							><v-icon color="tw-text-F2 ">mdi mdi-pencil</v-icon></v-btn
						>
						<v-btn
							width="50"
							height="50"
							class="tw-rounded-md"
							depressed
							fab
							@click="onResetUserForm"
							color="#DF9999"
							dark
							><v-icon color="tw-text-white"
								>mdi mdi-close</v-icon
							></v-btn
						>
					</div>
					<div class="tw-flex tw-space-s-3" v-if="update">
						<v-btn
							width="50"
							height="50"
							class="tw-rounded-md"
							depressed
							fab
							color="#F2CA51"
							dark
							@click="validateUser"
							><v-icon color="tw-text-white"
								>mdi mdi-check</v-icon
							></v-btn
						>
						<v-btn
							width="50"
							height="50"
							class="tw-rounded-md"
							depressed
							fab
							@click="onResetUserForm"
							color="#DF9999"
							dark
							><v-icon color="tw-text-white"
								>mdi mdi-close</v-icon
							></v-btn
						>
					</div>
				</div>
				<div>
					<v-row>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('branchName') }}
							</div>
							<v-text-field
								v-model="branchData.name"
								:placeholder="$t('name')"
								:rules="[rules.required($t('branchName'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="error.name ? error.name[0] : []"
								flat
								outlined
								name="name"
								class=" user-input"
								hide-details="auto"
								background-color="#FBFBFB"
							/>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('branchCode') }}
							</div>
							<v-text-field
								v-model="branchData.branchCode"
								:rules="[rules.required($t('branchCode'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="
									error.branchCode ? error.branchCode[0] : []
								"
								flat
								outlined
								name="branchCode"
								class=" user-input"
								hide-details="auto"
								background-color="#FBFBFB"
								placeholder="#### #### #### ####"
							/>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('representative-id') }}
							</div>
							<v-text-field
								v-model="branchData.iqamaNumber"
								:rules="[rules.required($t('representative-id'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="
									error.iqamaNumber ? error.iqamaNumber[0] : []
								"
								flat
								outlined
								name="iqamaNumber"
								class=" user-input"
								hide-details="auto"
								background-color="#FBFBFB"
								placeholder="#### #### #### ####"
							/>
						</v-col>
						<v-col cols="12">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('headers.details') }}
							</div>

							<v-textarea
								v-model="branchData.details"
								:rules="[rules.required($t('headers.details'))]"
								:placeholder="$t('message-description')"
								flat
								rows="1"
								outlined
								auto-grow
								name="details"
								background-color="#FBFBFB"
								class="custom-outlined tw-rounded-md tw-mt-8"
							></v-textarea>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('branch.representativeName') }}
							</div>
							<v-text-field
								v-model="branchData.representativeName"
								:placeholder="$t('headers.name')"
								:rules="[rules.required($t('name'))]"
								:error-messages="error.representativeName"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								flat
								outlined
								class="user-input"
								hide-details="auto"
								name="representativeName"
								background-color="#FBFBFB"
							/>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('representative-phone') }}
							</div>
							<v-text-field
								v-model="branchData.representativePhone"
								:placeholder="$t('status.status')"
								:items="['active', 'deactive']"
								:rules="[rules.required($t('phoneNumber'))]"
								:error-messages="error.representativePhone"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								flat
								outlined
								type="tel"
								class="user-input"
								hide-details="auto"
								name="representativePhone"
								background-color="#FBFBFB"
							/>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div class="">
								<span
									class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
								>
									{{ $t('representative-email') }}</span
								>
								<v-text-field
									v-model="branchData.representativeEmail"
									:placeholder="$t('headers.email')"
									:error-messages="error.representativeEmail"
									:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
									:rules="[rules.required($t('email')), rules.email]"
									flat
									outlined
									type="email"
									class="user-input"
									hide-details="auto"
									name="representativeEmail"
									background-color="#FBFBFB"
								/>
							</div>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('building-number') }}
							</div>
							<v-text-field
								v-model="branchData.address.buildingNumber"
								:placeholder="$t('building-number')"
								:error-messages="error.buildingNumber"
								:rules="[rules.required($t('building-number'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								flat
								outlined
								class="user-input"
								hide-details="auto"
								name="buildingNumber"
								background-color="#FBFBFB"
							/>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('branch.streetName') }}
							</div>
							<v-text-field
								outlined
								background-color="#FBFBFB"
								flat
								:rules="[rules.required($t('branch.streetName'))]"
								class=" user-input"
								v-model="branchData.address.streetName"
								hide-details="auto"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="
									error.branchCode ? error.branchCode : []
								"
							/>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('neighbourhood') }}
							</div>
							<v-text-field
								outlined
								background-color="#FBFBFB"
								flat
								:rules="[rules.required($t('neighbourhood'))]"
								class="user-input"
								v-model="branchData.address.neighborhoodName"
								hide-details="auto"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="
									error.neighborhoodName ? error.neighborhoodName : []
								"
							/>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('headers.city') }}
							</div>
							<v-text-field
								outlined
								background-color="#FBFBFB"
								flat
								:rules="[rules.required($t('headers.city'))]"
								class="user-input"
								v-model="branchData.address.city"
								hide-details="auto"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="
									error.unitNumber ? error.unitNumber : []
								"
							/>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('extraNumber') }}
							</div>
							<v-text-field
								outlined
								background-color="#FBFBFB"
								flat
								:rules="[rules.required($t('extraNumber'))]"
								class="user-input"
								v-model="branchData.address.extraNumber"
								hide-details="auto"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="
									error.extraNumber ? error.extraNumber : []
								"
							/>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('unitNumber') }}
							</div>
							<v-text-field
								outlined
								background-color="#FBFBFB"
								flat
								:rules="[rules.required($t('unitNumber'))]"
								class="user-input"
								v-model="branchData.address.unitNumber"
								hide-details="auto"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="
									error.unitNumber ? error.unitNumber : []
								"
							/>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('branch.postalCode') }}
							</div>
							<v-text-field
								outlined
								background-color="#FBFBFB"
								flat
								:rules="[rules.required($t('branch.postalCode'))]"
								class="user-input"
								v-model="branchData.address.postalCode"
								hide-details="auto"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="
									error.postalCode ? error.postalCode : []
								"
							/>
						</v-col>
					</v-row>
				</div>
				<v-simple-table v-if="branchData.attachment" class="tw-mt-4">
					<template v-slot:default>
						<tbody>
							<tr
								class="tw-border-t tw-border-b tw-border-opacity-10 tw-border-70"
							>
								<td>1</td>
								<td class="tw-space-s-2">
									<a
										:href="$image(branchData.attachment.attachedLink)"
										target="_blank"
									>
										<v-icon color="primary">mdi-file</v-icon>
										{{
											$get(branchData, 'attachment.attachmentName')
										}}
									</a>
								</td>
								<td>
									{{
										$get(
											branchData,
											'attachment.attachmentName',
											''
										).split('.')[0]
									}}
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<v-col v-if="update || onUpdate" cols="12">
					<div
						class="tw-border-dashed tw-border-2 tw-border-DF tw-rounded-lg tw-px-6 tw-py-4 tw-flex tw-items-center tw-justify-between tw-my-6"
					>
						<div class="tw-flex tw-flex-col tw-space-y-1.5">
							<span class="tw-font-medium">
								{{ $t('branch.letterOFAuth') }}
							</span>
							<span class="tw-font-medium tw-text-CA tw-text-xs">
								{{ $t('signUp.attachmentSizeHint') }}
							</span>
							<div
								v-if="copyOfCard && copyOfCard.file && copyOfCard.src"
							>
								<span class="tw-me-4">{{ $t('copyOfCard') }}</span>
								<a
									:href="
										copyOfCard.file &&
											copyOfCard.src &&
											copyOfCard.src
									"
									target="_blank"
								>
									<v-chip dark color="primary">
										<v-icon class="me-2">mdi-eye</v-icon>
										<span>{{ $t('copyOfCard') }}</span>
									</v-chip>
								</a>
							</div>
						</div>

						<v-btn
							@click="onRepresentativeAttachment"
							width="152"
							height="52"
							dark
							class="tw-rounded-md"
							depressed
							color="#F2CA51"
						>
							<icon-login class="tw-me-3 xl:tw-me-6" />
							<span
								class="xl:tw-text-lg tw-text-base tw-normal-case tw-font-normal"
							>
								{{ $t('actions.upload') }}
							</span>
						</v-btn>
					</div>
				</v-col>
			</v-card-text>
			<v-card-actions
				v-if="onUpdate || update"
				class="tw-mt-10 lg:tw-mt-14 tw-flex tw-p-0"
			>
				<v-btn
					outlined
					color="primary"
					height="58"
					class="tw-flex-1 tw-rounded-lg"
					text
					@click="onResetUserForm"
					><span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
						>{{ $t('actions.cancel') }}</span
					>
				</v-btn>
				<v-btn
					height="58"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
					depressed
					:loading="loading"
					type="submit"
					color="primary"
					><span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
						>{{ $t('actions.submit') }}</span
					>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import { get } from 'lodash'
import { mapGetters } from 'vuex'
import { omitEmpties } from 'vuelpers'
import { createFormMixin } from '@/mixins/form-mixin'

import UseFilePicker from '../../mixins/UseFilePicker'

const initialBranch = (v = {}) => ({
	id: v.id || null,
	name: v.name || '',
	branchCode: v.branchCode || '',
	details: v.details || '',
	representativeName: v.representativeName || '',
	representativeEmail: v.representativeEmail || '',
	representativePhone: v.representativePhone || '',
	iqamaNumber: v.iqamaNumber || '',
	status: v.status || '',
	branchableId: v?.branchableId,
	branchableType: v?.branchableType,
	address: {
		id: get(v, 'address[0].id') || null,
		buildingNumber: get(v, 'address.0.buildingNumber') || '',
		streetName: get(v, 'address.0.streetName') || '',
		neighborhoodName: get(v, 'address.0.neighborhoodName') || '',
		city: get(v, 'address.0.city') || '',
		postalCode: get(v, 'address.0.postalCode') || '',
		extraNumber: get(v, 'address.0.extraNumber') || '',
		unitNumber: get(v, 'address.0.unitNumber') || '',
	},
	attachment: v.attachment || null,
})
export default {
	name: 'BranchForm',
	mixins: [
		UseFilePicker,
		createFormMixin({
			rules: ['required', 'email', 'password'],
		}),
	],
	props: {
		branch: Object,
		onView: {
			type: Boolean,
			default: false,
		},
		onUpdate: {
			type: Boolean,
			default: false,
		},
		error: Object,
		loading: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		branch: {
			deep: true,
			immediate: true,
			handler(v) {
				this.branchData = initialBranch(v)
				this.copyOfCard = {
					file: null,
					attachedLink: null,
				}
			},
		},
	},
	data() {
		return {
			branchData: {},
			showBuyerAttachmentError: false,
			showUserPermissionError: false,
			menu: false,
			update: false,
			copyOfCard: {
				file: null,
				attachedLink: null,
			},
		}
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		// formatDate() {
		// 	console.log();
		// 	return this.branch.iqamaCardExpDate
		// 		? this.$m(this.branch.iqamaCardExpDate).format("Do MMM YYYY")
		// 		: "";
		// }
	},
	mounted() {},

	methods: {
		onResetUserForm() {
			this.$refs.form.reset()
			;(this.showBuyerAttachmentError = false),
				(this.showUserPermissionError = false),
				(this.update = false)
			this.copyOfCard = {
				file: null,
				attachedLink: null,
			}

			console.log('reset')
			this.$emit('onClose')
			this.branchData = initialBranch()
		},
		onRepresentativeAttachment() {
			this.openFilePicker({ multiple: false }, (image) => {
				console.log(image)

				this.copyOfCard = image
				this.showBuyerAttachmentError = false
			})
		},
		validateUser() {
			console.log('validateUser')
			if (this.onUpdate || this.update) {
				this.$emit(
					'UpdateBranch',
					omitEmpties({
						...this.branchData,
						file:
							this.copyOfCard && this.copyOfCard.file
								? this.copyOfCard.file
								: null,
					})
				)
			}
		},
		onUpdateFromView() {
			this.update = true
			console.log('onUpdateFromView')
			;(this.showBuyerAttachmentError = false),
				(this.showUserPermissionError = false)
		},
	},
}
</script>

<style>
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	color: black !important;
	opacity: 0.25;
	font-size: 16px !important;
}
</style>
