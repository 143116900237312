<template>
	<div>
		<base-data-table
			:title="$t('branches')"
			:headers="headers"
			:actions="actions"
			:provider="$branches"
			:fetch-function="getBranches"
			translate
			@view="onViewBranch"
			@update="onUpdateBranch"
			@delete="onDeleteBranch"
			@click:add="onInitBranch"
			@changeBranchStatus="onChangeStatus"
		>
			<template v-slot:[`item.serial`]="{ index }">
				{{ $branches.perPage * ($branches.currentPage - 1) + index + 1 }}
			</template>
			<template v-slot:[`item.representativePhone`]="{ item }">
				<base-phone-number
					:phone="item.representativePhone"
					class="tw-whitespace-nowrap tw-flex tw-items-center tw-space-s-3"
				>
					<template #default="{ phone, country }">
						<v-avatar v-if="country" size="20">
							<base-flag height="20" width="20" :code="country" />
						</v-avatar>
						<span>{{ phone }}</span>
					</template>
				</base-phone-number>
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<div
					:class="item.status ? 'tw-text-AA' : 'tw-text-72'"
					class="tw-capitalize tw-text-sm tw-font-semibold"
				>
					{{ +item.status ? 'active' : 'inactive' }}
				</div>
			</template>
			<template v-slot:[`item.activatedAt`]="{ item }">
				<div>
					{{ $m(item.activatedAt).format('Do MMM YYYY') }}
				</div>
			</template>
		</base-data-table>

		<v-dialog
			persistent
			max-width="1198"
			v-model="branchForm.dialog"
			content-class="tw-rounded-2xl tw-shadow-lg"
			scrollable
		>
			<BranchForm
				@onClose="onResetUserForm"
				:branch="branchForm.data"
				v-if="branchForm.dialog"
				:onView="onView"
				:loading="loading"
				:onUpdate="onUpdate"
				@UpdateBranch="handleUpdateBranch"
				:error="error"
			/>
		</v-dialog>

		<DialogDeleteConfirmation
			v-model="confirmDialog"
			:id="onDeleteId"
			:title="$t('branchText')"
			:delete-function="deleteBranch"
		/>

		<v-dialog
			content-class="tw-rounded-3xl tw-bg-white"
			persistent
			max-width="676"
			v-model="confirmStatusDialog"
		>
			<v-card elevation="0" class="tw-p-0 tw-mx-0">
				<div class="tw-text-center tw-py-5 tw-px-6 lg:tw-py-10 lg:tw-px-9">
					<h1 class="tw-text-2xl tw-font-semibold">
						{{
							onChangeStatusData.status
								? 'Deactive Branch'
								: 'Active Branch'
						}}
					</h1>
					<div
						class="tw-text-black tw-text-opacity-25 tw-mt-6 tw-mb-9 xl:tw-mt-8 xl:tw-mb-14 tw-text-center"
					>
						{{ $t('sure-change-status', [$t('branchText')]) }}
					</div>
					<v-card-actions class="tw-flex tw-space-s-4">
						<v-btn
							depressed
							color="#DF9999"
							outlined
							@click="confirmStatusDialog = false"
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							><span class="tw-capitalize tw-text-lg tw-font-medium">{{
								$t('actions.cancel')
							}}</span></v-btn
						>
						<v-btn
							depressed
							color="#DF9999"
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							@click="handleChangeBranchStatus"
							dark
							:loading="loading"
						>
							<span class="tw-capitalize tw-text-lg tw-font-medium">{{
								$t('actions.confirm')
							}}</span></v-btn
						>
					</v-card-actions>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { toFormData } from 'vuelpers'
import { mapActions, mapGetters } from 'vuex'

import BranchForm from '@/components/forms/BranchForm.vue'
import DialogDeleteConfirmation from '../../components/dialogs/DialogDeleteConfirmation.vue'

export default {
	name: 'Branch',
	components: {
		BranchForm,
		DialogDeleteConfirmation,
	},
	data: () => ({
		errors: {},
		view: {
			modal: false,
			data: null,
		},

		confirmDialog: false,
		onDeleteId: null,
		onDeactiveId: null,
		isBranchsLoaded: false,
		confirmStatusDialog: false,
		isBranchsRefreshing: false,
		remove: {
			id: null,
			modal: false,
		},
		headers: [
			{ text: 'headers.ns', value: 'serial' },
			{ text: 'headers.branchName', value: 'name' },
			{ text: 'headers.branchCode', value: 'branchCode' },
			{ text: 'headers.phone', value: 'representativePhone' },
			{ text: 'headers.email', value: 'representativeEmail' },
			{ text: 'headers.branchRepresentative', value: 'representativeName' },
			{ text: 'headers.status', value: 'status' },
			{ text: 'headers.actions', value: 'actions' },
		],
		onChangeStatusData: {},

		onView: false,
		onUpdate: false,
		searchForm: false,
		error: {},
		branchForm: {
			dialog: false,
			data: null,
		},
		loading: false,
	}),
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('branch', ['$branches']),
		actions() {
			return [
				{
					text: this.$t('view-details'),
					event: 'view',
					icon: 'mdi-eye-outline',
				},
				{
					text: this.$t('actions.editDetails'),
					event: 'update',
					icon: 'mdi-pencil-outline',
				},
				{
					icon: 'mdi-checkbox-intermediate',
					event: 'changeBranchStatus',
					text: (v) => {
						return v.status
							? this.$t('actions.deactivate')
							: this.$t('actions.activate')
					},
				},
				{
					divider: true,
				},
				{
					icon: 'mdi-delete-outline',
					event: 'delete',
					color: 'error',
					text: this.$t('actions.delete'),
				},
			]
		},
	},
	methods: {
		...mapActions('settings', ['toggleImagePreview']),
		...mapActions('branch', [
			'getBranches',
			'deleteBranch',
			'updateBranch',
			'createBranch',
		]),
		onSubmitForm() {},
		onInitDelete(item) {
			this.remove = { id: item.id, modal: true }
		},
		onViewBranch(branch) {
			console.log(branch)
			this.branchForm.data = branch
			this.onView = true
			this.branchForm.dialog = true
		},
		onUpdateBranch(user) {
			console.log(user)
			this.branchForm.data = user
			this.branchForm.dialog = true
			this.onUpdate = true
		},
		onDeleteBranch(branch) {
			this.confirmDialog = true
			this.onDeleteId = branch.id
			console.log(this.onDeleteId)
		},
		onChangeStatus(branch) {
			this.confirmStatusDialog = true
			this.onChangeStatusData = branch

			console.log(
				'onChangeStatusDataonChangeStatusData',
				+!this.onChangeStatusData.status
			)
		},
		async handleCreateBranch(branch) {
			console.log('handleCreateBranch', branch)
		},
		async handleUpdateBranch(item) {
			delete item.attachment

			this.loading = true
			let [err] = await this.updateBranch(
				toFormData(item, { _method: 'PATCH', convertCase: 'snake_case' })
			)
			this.loading = false

			if (err) {
				this.error = err.errors
				this.$toast.error(
					this.$t('error-updating', [this.$t('branchText')])
				)
				return
			}
			this.$toast.success(this.$t('success-update', [this.$t('branchText')]))
			this.onResetUserForm()
		},
		async handleChangeBranchStatus() {
			let status = this.onChangeStatusData.status == 1 ? 0 : 1

			let data = toFormData(
				{
					id: this.onChangeStatusData.id,
					status: status,
				},
				{
					_method: 'PATCH',
					convertCase: 'snake_case',
				}
			)
			this.loading = true
			let [err] = await this.updateBranch(data)
			this.loading = false

			if (err) {
				return this.$toast.error(
					this.$t('error-status', [this.$t('branchText')])
				)
			}
			this.confirmStatusDialog = false
			this.$toast.success(this.$t('success-status', [this.$t('branchText')]))
		},
		onInitBranch() {
			this.$router.push(
				this.$currentUser.isBuyer
					? '/branches/create'
					: '/supplier/create-branch'
			)
		},
		onResetUserForm() {
			this.branchForm.data = {}
			this.branchForm.dialog = false
			this.onUpdate = false
			this.onView = false
			this.error = {}
		},
	},
}
</script>
